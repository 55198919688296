<template lang="pug">
  .confirm-delete-dialog(ref="dialog")
    NioIconFramer(
      icon-name="display-warning"
    )
    h3.nio-h3.text-primary-darker Are you sure?
    p.nio-p.text-primary-dark You are about to delete this field {{ this.fieldType === 'object' || this.fieldType === 'array' ? 'and all of its child fields' : ''}}. This cannot be undone.
    .actions
      NioButton(
        normal-secondary
        @click="cancel"
      ) Cancel
      NioButton(
        caution-filled-prepend
        @click="confirm"
      ) Continue
</template>

<script>


export default {
  props: ['fieldType'],
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('confirm')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.confirm-delete-dialog
  padding: 6.25rem 1.5rem 4.875rem 1.5rem
  background-color: $c-white
  border: 0.0625rem solid $c-primary-lighter
  border-radius: 0.75rem
  display: flex
  flex-direction: column
  align-items: center
  .nio-icon-framer
    margin-bottom: 1rem
  h3
    margin-bottom: 1.5rem
  p
    margin-bottom: 4.75rem
    text-align: center
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + * 
      margin-left: 1rem  
</style>