<template lang="pug">
.review
  .display-row.permalink-tags
    .cell
      .nio-h7.text-primary-dark Description
      .nio-p.text-primary-dark(
        v-if="reviewData.description && reviewData.description.length > 0"
      ) {{ reviewData.description }}
      .nio-p.text-primary-dark(v-else) None provided
 
  .display-row
    .cell
      .nio-h7.text-primary-dark Tags
      .tags(v-if="reviewData.tags && reviewData.tags.length > 0")
        NioPill(v-for="tag in reviewData.tags", tag, :key="tag") {{ tag }}
      .nio-p.text-primary-dark(v-else) None provided

  .display-row.file-info.border-top
    .cell
      .nio-h7.text-primary-dark File Type
      .nio-p.text-primary-dark {{ getReadableString('type', reviewData.schema.file_config.type) }}
    .cell(v-if="reviewData.schema.file_config.type === 'flat'")
      .nio-h7.text-primary-dark Delimiter
      .nio-p.text-primary-dark {{ getReadableString('delimiter', reviewData.schema.file_config.delimiter) }}
    .cell(v-if="reviewData.schema.file_config.type === 'flat'")
      .nio-h7.text-primary-dark Escape Character
      .nio-p.text-primary-dark {{ getReadableString('escapeCharacter', reviewData.schema.file_config.escape) }}
    .cell(v-if="reviewData.schema.file_config.type === 'flat'")
      .nio-h7.text-primary-dark Headers
      .nio-p.text-primary-dark {{ getReadableString('incHeaders', reviewData.schema.file_config.header) }}
    .cell(v-if="reviewData.schema.file_config.type === 'flat'")
      .nio-h7.text-primary-dark Quote
      .nio-p.text-primary-dark {{ getReadableString('quote', reviewData.schema.file_config.quote) }}
    .cell(v-if="reviewData.schema.file_config.type === 'flat'")
      .nio-h7.text-primary-dark Write mode
      .nio-p.text-primary-dark {{ getReadableString('writeMode', reviewData.write_mode) }}
  NioDialog(
    v-model="editingDataset"
  )
    EditDatasetDialog(
      v-if="reviewData"
      :dataset="reviewData",
      @cancel="editingDataset = false;",
      @save="updateDataset($event)"
    )
</template>

<script>

import EditDatasetDialog from './EditDatasetDialog'
export default {
  components: {
    EditDatasetDialog
  },
  props: {
    reviewData: { type: Object, required: true },
    isEdit:  { type: Boolean, required: false, default: false }
  },
  data: () => ({
    editingDataset: false,
    loading: true,
    accepted: false,
    fileTypes: [{
      name: 'CSV',
      value: 'flat'
    }, {
      name: 'JSON',
      value: 'json'
    }],
    specialCharacters: [{
      name: 'Pipe - "|"',
      value: '|'
    }, {
      name: 'Comma - ","',
      value: ','
    }, {
      name: 'Semi-colon - ";"',
      value: ';'
    }, {
      name: 'Tab - "\t"',
      value: '\t'
    }, {
      name: 'Space - " "',
      value: ' '
    },
    {
      name: 'Quote - \"',
      value: '"'
    },
    {
      name: 'Tilde - ~',
      value: '~'
    },
    {
      name: 'Custom',
      value: 'Custom'
    }],
    writeModes: [{
      name: 'Incremental updates',
      value: 'append'
    }, {
      name: 'Complete file updates',
      value: 'overwrite'
    }],
    status: 'draft',
    uploadPath: 'upload'
  }),
  watch: {
    accepted(val) {
      if (val) {
        this.$emit('stepPayloadChanged', true)
      } else {
        this.$emit('stepPayloadChanged', null)
      }
    }
  },
  mounted() {
    this.loading = false
  },
  methods: {
    updateDataset(payload) {
      this.$emit('updateDataset', payload)
    },
    getReadableString(property, value) { // TODO: refactor, combine with version in ReviewStep
      let specialChar = this.specialCharacters.find(char => char.value === value)
      switch (property) {
        case 'type':
          return value.toUpperCase()
        case 'delimiter':
          return specialChar?.name !== 'Custom' ? specialChar.name : `Custom - ${value}`
        case 'escapeCharacter':
          return specialChar ? specialChar.name : value
        case 'incHeaders':
          return value ? 'Yes' : 'No'
        case 'quote':
          return specialChar ? specialChar.name : value
        case 'writeMode':
          return this.writeModes.find(mode => mode.value === value)?.name
        default:
          return value
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"
.nio-dialog
  z-index: 99
.review
  .display-row
    display: flex
    align-items: flex-start
    padding: 1.5rem 0rem
    .cell
      flex: 1 1 0rem
      .nio-p, .tags
        min-height: 1.25rem
      .tags
        .nio-pill
          margin-right: 0.5rem
          margin-bottom: 0.5rem
  .display-row.file-info
    .cell
      .nio-p
        height: 1.25rem
    & > .cell + .cell
      padding-left: 1.5rem
      border-left: 0.0625rem solid $c-primary-lighter

  .edit-button
    height: auto !important
    font-size: 14px !important
    text-transform: capitalize
    ::v-deep .v-btn__content 
      justify-content: flex-start !important

  .border-top
    border-top: 0.0625rem solid $c-primary-lighter
</style>
