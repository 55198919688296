<template lang="pug">
  .attribute-mappings-error-dialog(ref="dialog")
    NioIconFramer(
      icon-name="display-warning"
    )
    h3.nio-h3.text-primary-darker Attribute Mappings Error
    template(v-if="forNewDataset")
      p.nio-p-large.text-primary-dark Your dataset was created, but your attribute mappings could not be created. 
    template(v-else)
      p.nio-p-large.text-primary-dark Your attribute mappings could not be created. The following errors were returned from the server:
    .error-messages(v-if="errors && errors.length > 0") 
      .error-message.nio-p.text-primary-dark(v-for="error of errors") {{ error }}
    template(v-if="forNewDataset")
      p.nio-p-large.text-primary-dark Don't worry, you can try again to create attribute mappings from the Datasets page. If you continue to experience problems, please contact a member of the Narrative Partner Success team.
    template(v-else)
      p.nio-p-large.text-primary-dark Please try your request again.  If you continue to experience problems, please contact a member of the Narrative Partner Success team.
    .actions(v-if="forNewDataset")
      NioButton(
        normal-secondary
        @click="handleAction('addFiles')"
      ) Small File Upload
        NioTooltip(
          heading="Small File Uploads"
          message="Add small dataset files directly through Dataset Manager."
          open-on-hover
        )
      NioButton(
        normal-secondary
        @click="handleAction('sources')"
      ) S3 Ingestion
        NioTooltip(
          heading="S3 Ingestion"
          message="Learn how to send large dataset files directly to S3."
          link-text="more info"
          link-href="https://kb.narrative.io/how-do-i-ingest-data-using-my-managed-bucket"
          open-on-hover
        )
      NioButton(
        normal-secondary
        @click="handleAction('datasets')"
      ) View Your Datasets
    .actions(v-else)
      NioButton(
        normal-secondary
        @click="close"
      ) Ok
</template>

<script>


export default {
  props: {
    forNewDataset: { type: Boolean, required: false, default:true },
    errors: { type: Array, required: false }
  },
  mounted() {
    parent.postMessage({
      name: 'errorDialog',
      payload: null
    },"*")
    setTimeout(() => {
      this.$emit('cancel')
    }, 300000); // allow 5 minutes before closing dialog
  },
  methods: {
    close() {
      this.$emit('close')
    },
    handleAction(action) {
      this.$emit('action', action)
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.attribute-mappings-error-dialog
  padding: 6.25rem 1.5rem 4.875rem 1.5rem
  background-color: $c-white
  border: 0.0625rem solid $c-primary-lighter
  border-radius: 0.75rem
  display: flex
  flex-direction: column
  align-items: center
  .nio-icon-framer
    margin-bottom: 1rem
  h3
    margin-bottom: 0.5rem
  p    
    margin-bottom: 1.5rem
    text-align: center
  .error-messages
    width: 100%
    margin: 1rem auto 2.5rem auto
    padding: 1rem
    border: 0.0625rem solid $c-primary-lighter
    background-color: $c-canvas
    & > .error-message + .error-message
      margin-top: 0.5rem
    .error-message
      text-align: center
  .actions
    display: flex
    justify-content: center
    align-items: center
    margin-top: 1.5rem
    & > * + * 
      margin-left: 1rem  
</style>