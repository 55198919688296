import { itemByPath } from '@/shared/utils/datasetConversions'
import { getAttributeFromPath } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'

const forceLowerAttributes = [
  'unique_id',
  'mobile_id_unique_identifier',
  'apple_idfa',
  'android_advertising_id',
  'ttd_id',
  'md5_hashed_email',
  'sha1_hashed_email',
  'sha256_hashed_email',
  'hashed_email'
]

let makeAttributeMappings = (datasetId, mappings, properties, attributes) => {
  const results = []
  if (!mappings || mappings.length === 0) {
    return []
  }
  const uniqueAttributeIds = mappings.filter(mapping => isCompleteMapping(mapping)).reduce((acc, mapping) => {
    if (!acc.includes(mapping.attribute.id)) {
      return [...acc, mapping.attribute.id]
    }
    return acc
  }, [])
  uniqueAttributeIds.forEach(attributeId => {
    const newMappings = makePropertyMappings(attributeId, mappings, properties, attributes)
    const result = {
      attribute_id: attributeId,
      dataset_id: datasetId,
      mapping: {}
    }
    if (newMappings.length > 0 && newMappings[0].path) {
      result.mapping.type = 'object_mapping'
      result.mapping.property_mappings = newMappings
    } else {
      result.mapping.type = 'value_mapping'
      result.mapping.expression = newMappings[0].expression
    }
    results.push(result)
  })
  return results
}

let makePropertyMappings = (attributeId, mappings, properties, attributes) => {
  const propertyMappings = []
  mappings.filter(mapping => isCompleteMapping(mapping)).forEach(mapping => {
    if (mapping.attribute.id === attributeId) {
      const arrayPath = mapping.attributeProperty.split('.')
      arrayPath.shift()
      let expression
      if (forceLowerAttributes.find(attr => `${attr}.value` === mapping.attributeProperty)) {
        expression = `lower(${mapping.property.toLowerCase()})`
      } else {
        let path = mapping.attributeProperty.split('.').reduce((acc, pathElement, index) => {
          if (index === 0) {
            return [{
              id: attributeId,
              name: pathElement
            }]
          } else {
            return [...acc, pathElement]
          }
        }, [])
        const parentAttribute = attributes.find(attribute => attribute.id === attributeId)
        const property = getAttributeFromPath(path, parentAttribute)
        if (property.type === 'binary' && property.format === 'geometry') {
          if (property && property.format === 'geometry') {
            expression = `ST_GeomFromWKT(${mapping.property.toLowerCase()})`
          }
        } else {
          expression = mapping.property.toLowerCase()
        }
      }
      const newMapping = {
        expression: expression,
      }
      if (arrayPath.length > 0) {
        newMapping.path = arrayPath.join('.')
      }
      propertyMappings.push(newMapping)
    }
  })
  return propertyMappings
}

let isCompleteMapping = (mapping) => {
  return Boolean(mapping.property && mapping.attribute && mapping.attributeProperty)
}

let getPropertyType = (properties, propertyName, path) => {
  if (!path) {
    path = propertyName
  }
  let pathElements = path.split('.')
  const item = itemByPath(properties, pathElements)
  return item && item.type ? item.type : undefined
}

let getProperty = (properties, propertyName, path) => {
  if (!path) {
    path = propertyName
  }
  let pathElements = path.split('.')
  return itemByPath(properties, pathElements)
}

export {
  makeAttributeMappings,
  getPropertyType
}