<template lang="pug">
  .user-proposed-attribute-mappings
    .filter-header
      .title-description
        .filter-title.nio-h4.text-primary-darker Proposed attribute mapping (optional)
        .description.nio-p.text-primary-dark Map your data to attributes available on Narrative.
      .filter-value
        .proposed-mapping(
          v-for="(mapping, index) of mappings"
          :key="mapping.id"
        )
          .mapping-inputs
            .mapping-row
              .mapping-row-inputs
                .mapping-row-input
                  NioAutocomplete(
                    v-model="mapping.property"
                    :items="makeProperties"
                    label="Select a Column"
                    item-value="path"
                    item-text="path"
                    @change="mappingAttributeChanged(mapping)"
                  )
                .mapping-row-input
                  NioAutocomplete(
                    v-model="mapping.attribute" 
                    :items="attributes" 
                    label="Narrative Attribute"
                    return-object
                    item-text="display_name"
                    @change="mappingAttributeChanged(mapping)"
                  )
            .mapping-row(v-if="mapping.attribute")
              .mapping-row-inputs
                .mapping-row-input
                  NioAutocomplete(
                    v-model="mapping.attributeProperty" 
                    :items="makeAttributePropertyItems(mapping)" 
                    label="Property"      
                    :no-data-text="makeNoDataText(mapping)"
                  )
                    template(
                      v-slot:item="{ item }" 
                    )
                      span(:class="{'nio-bold': mapping.requiredProperties && mapping.requiredProperties.includes(item)}") {{ item }}
                      span(v-if="mapping.requiredProperties && mapping.requiredProperties.includes(item)") * 
                .mapping-row-input
                  .required-properties-message(v-if="mapping.requiredProperties && mapping.requiredProperties.length > 0")
                    .message-row.nio-p.small.text-primary-darker.nio-bold Required
                      
                      span(style="margin-right: 4px") :
                      span(v-for="(requiredProperty, index) of mapping.requiredProperties")
                        span.nio-p.small.text-primary-dark {{ `${index > 0 ? ', ' : ''}${requiredProperty}` }}
                      NioTooltip(
                        heading="This attribute has required properties"
                        message="In order to create a mapping for this attribute, you must also create a mapping for each of the attribute's required properties. Note that mappings must be created from columns of the same type, so your dataset must contain the appropriate column types."
                        open-on-hover
                      )
                .spacer
          .remove-mapping
            button(
              @click="removeMapping(index)"
            )
              NioIcon(
                :color="getThemeColor('primaryDark')" 
                :size="14"
                name="utility-minus" 
              )
      .add-mapping
        NioButton(
          normal-secondary
          :disabled="!canAddMapping"
          @click="addMapping"
        ) Add Attribute Mapping
      .save-attribute-mappings(v-if="forExistingDataset")
        NioButton(
          normal-primary
          :disabled="!canSaveMappings"
          @click="saveMappings"
        ) Save
</template>

<script>

import { getThemeColor } from '@narrative.io/tackle-box/src/modules/app/theme/theme'
import { makeRandomId } from '@narrative.io/tackle-box/src/modules/helpers'
import { getPropertyType } from '@/modules/mappingsModule'
import { getAttributeFromPath, makeDotDelimitedPropertyPath } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'

export default {
  props: {
    properties: { type: Array, required: true },
    attributes: { type: Array, required: false, default: () => [] },
    forExistingDataset: { type: Boolean, required: false, default: false },
    existingMappings: { type: Array, required: false, default: () => [] }
  },
  data: () => ({
    mappings: [{
      property: null,
      attribute: null,
      attributeProperty: null,
      requiredProperties: null,
      id: makeRandomId()
    }],
    existingPropertyMessages: [],
    stuff: null
  }),
  computed: {
    canAddMapping() {
      return !this.mappings.find(mapping => Boolean(!mapping.property || !mapping.attribute || !mapping.attributeProperty))
    },
    canSaveMappings() {
      return this.mappings.length > 0 && !this.mappings.find(mapping => Boolean(!mapping.property || !mapping.attribute || !mapping.attributeProperty))
    },
    makeProperties() {
      return this.properties.reduce((acc, property) => {
        return [...acc, ...this.makeAttributeProperties(property)]    
      }, []).map(path => {
        return {
          path: path,
          disabled: false
        }
      })
    }
  },
  watch: {
    mappings: {
      deep: true,
      handler(val) {
        this.$emit('attributeMappingsChanged', val)
      }
    },
    properties: {
      deep: true,
      handler() {
        const properties = this.properties.reduce((acc, property) => {
          return [...acc, ...this.makeAttributeProperties(property)]    
        }, [])
        this.mappings.forEach((mapping, index) => {
          if (mapping.property && !properties.includes(mapping.property)) {
            this.removeMapping(index)
          }
        })
        if (this.mappings.length === 0) {
          this.mappings.push({
            property: null,
            attribute: null,
            attributeProperty: null,
            id: makeRandomId()
          })
        }
      }
    }
  },
  methods: {
    getThemeColor(themeColor) {
      return getThemeColor(themeColor)
    },
    isGeographyAttribute(property) {
      return property && property.type === 'binary' && property.format === 'geometry'
    },
    makeAttributePropertyItems(mapping, returnAll) {
      const attributeProperties = this.makeAttributeProperties(mapping.attribute)
      if (mapping.property) {
        const type = getPropertyType(this.properties, mapping.property)
        return attributeProperties.reduce((acc, attributeProperty) => {
          const path = attributeProperty.split('.')
          const parentAttribute = this.attributes.find(attribute => attribute.name === path[0])
          const parentPathElement = path.shift()
          path.unshift({
            id: parentAttribute.id,
            name: parentAttribute.name
          })
          const foundAttribute = getAttributeFromPath(path, parentAttribute)
          if (foundAttribute && (returnAll || ((this.isGeographyAttribute(foundAttribute) || foundAttribute.type === type) && !this.attributePropertyHasExistingMapping(path, mapping)))) {
            return [...acc, attributeProperty]
          }
          return acc
        }, []) 
      }
      return attributeProperties
    },
    makeNoDataText(mapping) {
      const attributeProperties = this.makeAttributeProperties(mapping.attribute)
      let existingProperty = false
      if (mapping.property) {
        existingProperty = attributeProperties.find(attributeProperty => {
          const path = attributeProperty.split('.')
          const parentAttribute = this.attributes.find(attribute => attribute.name === path[0])
          const parentPathElement = path.shift()
          path.unshift({
            id: parentAttribute.id,
            name: parentAttribute.name
          })
          const foundAttribute = getAttributeFromPath(path, parentAttribute)
          if (foundAttribute) {
            const existingMapping = this.attributePropertyHasExistingMapping(path, mapping)
            if (existingMapping) {
              return true
            } else {
              return false
            }
          }
          return false
        }) 
      }
      return existingProperty ? 'No remaining available properties' : 'No available properties for column type'
    },
    makeAttributeProperties(attribute, properties, parentPathString, type) {
      if (!properties) {
        properties = []
      }
      const parentPath = parentPathString ? parentPathString : ''
      let nextPath = parentPath.length < 1 ? attribute.name : `${parentPath}.${attribute.name}`
      nextPath = nextPath.replace('.nioArrayItemsField', '')
      if (attribute.type === 'object') {
        if (attribute.properties.length) {
          const childProperties = attribute.properties.reduce((acc, property) => {
            return [...acc, ...this.makeAttributeProperties({
              ...property,
              name: property.name
            }, [], nextPath)]
          }, [])
          return [...properties, ...childProperties]
        } else {
          const childProperties = Object.keys(attribute.properties).reduce((acc, key) => {
            return [...acc, ...this.makeAttributeProperties({
              ...attribute.properties[key],
              name: key
            }, [], nextPath)]
          }, [])
          return [...properties, ...childProperties]
        }
      } else if (attribute.type === 'array') {
        if (attribute.items.length) {
          return properties
        } else {
          return [...properties, ...this.makeAttributeProperties(attribute.items, properties, nextPath)]
        }
      } else {
        return [...properties, nextPath]
      }    
    },
    makeRequiredProperties(attribute, parentPath, required, propertyKey) {
      if (!parentPath) {
        parentPath = attribute.name
      }
      if (!required) {
        required = attribute.required
      }
      if (!propertyKey) {
        propertyKey = attribute.name
      }
      if (attribute.type === 'object') {
        if (Object.keys(attribute.properties).length) {
          return Object.keys(attribute.properties).reduce((acc, key) => {
            if (attribute.required && attribute.required.length) {
              let requiredProperties = attribute.required.map(requiredproperty => {
                return `${parentPath}.${requiredproperty}`
              })
              return [...acc, ...this.makeRequiredProperties(
                attribute.properties[key], 
                `${parentPath}.${key}`,
                requiredProperties,
                key
              )]
            }
            return acc
          }, [])
        }
        return []
      } else if (attribute.type === 'array') {
        return []
      } else {
        if (required && required.length && required.includes(`${parentPath}`)) {
          return [parentPath]
        }
        return []
      }    
    },
    attributePropertyHasExistingMapping(path, mapping) {
      const dotDelimitedPath = makeDotDelimitedPropertyPath(path)
      const fullPath = dotDelimitedPath.length > 0 ? `${path[0].name}.${makeDotDelimitedPropertyPath(path)}` : path[0].name
      if (this.mappings.find(currMapping => currMapping.attributeProperty === fullPath && mapping.id !== currMapping.id) !== undefined) {
        return true
      }
      if (this.existingMappings.find(existingMapping => {
        if (existingMapping.attribute_id === path[0].id) {
          if (existingMapping.mapping.type === 'object_mapping') {
            if (existingMapping.mapping.property_mappings && existingMapping.mapping.property_mappings.length) {
              if (existingMapping.mapping.property_mappings.find(propertyMapping => {
                return `${path[0].name}.${makeDotDelimitedPropertyPath(path)}` === `${this.attributes.find(attribute => existingMapping.attribute_id === attribute.id).name}.${propertyMapping.path}`
              })) {
                return true
              }
            }
          } else if (existingMapping.mapping.type === 'value_mapping') {
            return true
          }
        }
        return false
      })) {
        return true
      }
      return false
    },
    mappingAttributeChanged(mapping) {
      mapping.attributeProperty = null
      mapping.id = makeRandomId()
      if (mapping.attribute) {
        mapping.requiredProperties = this.makeRequiredProperties(mapping.attribute)   
        if (mapping.requiredProperties.length === 0) {
          const allProperties = this.makeAttributePropertyItems(mapping, true)
          if (allProperties.length > 0) {
            mapping.requiredProperties = [allProperties[0]]
          }
        }

      } else {
        mapping.requiredProperies = null
      }
    },
    addMapping() {
      this.mappings.push({
        property: null,
        attribute: null,
        attributeProperty: null,
        id: makeRandomId()
      })
    },
    removeMapping(index) {
      this.mappings.splice(index, 1)
    },
    saveMappings() {
      this.mappings =  [{
        property: null,
        attribute: null,
        attributeProperty: null,
        id: makeRandomId()
      }]
      this.$emit('saveAttributeMappings')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-group"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.user-proposed-attribute-mappings
  +nio-filter-group
  .filter-header
    +nio-filter-header
    flex-direction: column
    .title-description
      margin-bottom: 1.5rem
    .filter-value
      width: 100%
      .mappings-exceed-properties
        text-align: center
        margin: 1.5rem auto
        max-width: 31.25rem
      .proposed-mapping
        display: flex
        width: 100%
        border-bottom: 0.0625rem solid $c-primary-lighter
        padding-bottom: 1rem
        .mapping-inputs
          display: flex
          flex-direction: column
          flex-grow: 2
          .mapping-row
            display: flex
            justify-content: space-between
            width: 100%
            height: 54px
            .mapping-row-inputs
              width: 100%
              display: flex
              .mapping-row-input
                flex-basis: 50%
                flex-shrink: 0
                margin-bottom: 0rem
                .required-properties-message
                  padding-right: 24px
              & > * + * 
                margin-left: 0.75rem
          & > .mapping-row + .mapping-row
            margin-top: 1rem
        .remove-mapping
          display: flex
          flex-grow: 0
          flex-shrink: 0
          width: 3.25rem
          margin-left: 0.75rem
          align-self: flex-end
          button
            width: 3.25rem
            height: 3.25rem
            border: 0.0625rem solid $c-primary-lighter
            border-radius: 0.5rem
            &:hover
              cursor: pointer
              background-color: $c-canvas 
      & > .proposed-mapping + .proposed-mapping
        margin-top: 1rem !important
    .add-mapping
      width: 100%
      margin-top: 1.5rem
      display: flex
      justify-content: flex-end
    .save-attribute-mappings
      margin-top: 1.5rem
      display: flex
      justify-content: center
</style>