<template lang="pug">
  .ingestion-upload-dialog(ref="dialog")
    template(v-if="dataset.write_mode === 'overwrite' && overwriteWarningDismissed === false")
      NioIconFramer(
        icon-name="display-warning"
      )
      h2.nio-h3.text-primary-darker Warning!
      p.nio-p.text-primary-dark This dataset has a write mode of <strong>overwrite</strong>. A file uploaded here will overwrite the entire contents of data previously ingested for this dataset. Are you sure you want to continue?
      //- p.nio-p.text-primary-dark Narrative uses the JSON Lines standard when inferring the dataset schema from the files you provide. To learn more about the JSON Lines format, visit <a href="https://jsonlines.org/" target="_blank">https://jsonlines.org/</a>
      .warning-actions
        NioButton(
          normal-secondary
          @click="close"
        ) Cancel    
        NioButton(
          caution-filled-prepend
          @click="overwriteWarningDismissed = true"
        ) Proceed      
    template(v-else)
      h2.nio-h2.text-primary-darker Upload files to dataset
      p.nio-p.text-primary-dark Upload times vary depending on your file size and upload speed. For files over 500MB, we recommend using an AWS S3 managed bucket. <a href="https://kb.narrative.io/how-do-i-set-up-a-managed-bucket">Learn more</a>
      NioFileChooser(
        v-model="file" 
        :state="chooserState"
        :percent-complete="10"
        :max-file-size="5000000000"
        :validate-fn="() => true"
        :valid="fileValid"
        action-label="Upload to Dataset"
        success-msg=""
        in-progress-msg="Uploading file. This could take some time..."
        error-msg="There was an error uploading your file. Please contact a member of the Narrative support team if the problem persists."
        general-error-msg="There was an error uploading your file. Please contact a member of the Narrative support team if the problem persists."
        filesize-error-msg="This file exceeds the maximum file size of 5GB"
        instructions=""
        indeterminate
        @changed="fileChanged($event)" 
        @actionClicked="execute"
        @cancelClicked="cancel"
        @stateChanged="setState($event)"
      )
        template(v-slot:instructions)
        template(v-slot:success-actions)        
          NioButton(
            icon-name="utility-arrow-left"
            normal-primary-prepend
            @click="close"
          ) I'm done
          NioButton(
            normal-secondary
            @click="reset"
          ) Upload another file
        template(v-slot:error-actions)        
          NioButton(
            normal-primary 
            @click="reset"
          ) Start Over		
      .upload-actions
        NioButton(
          icon-name="utility-arrow-left"
          normal-tertiary-prepend
          @click="close"
        ) {{ backButtonLabel }}
      .messages
        NioAlert(
          :visible="showFilesizeWarning"
          message="This file is very large and may take awhile to upload to our server. You may want to consider using a managed AWS S3 Bucket instead."
          link-text="LEARN MORE"
          link-href="https://kb.narrative.io/how-do-i-ingest-data-using-my-managed-bucket"
          warning
          @dismiss="filesizeWarning = false"
        )
</template>

<script>

import { uploadFileForIngestion } from '@/modules/ingestionUpload'

export default {
  props: {
    dataset: { type: Object, required: true },
    backButtonLabel: { type: String, required: false, default: 'Back to Datasets'}
  },
  data: () => ({
    loading: false,
    file: null,
    chooserState: 'initial',
    valid: true,
    percentComplete: 0,
    numErrors: 0,
    fileValid: true,
    minSizeErrorDialog: false,
    maxFilesizeDialog: false,
    filesizeWarning: false,
    overwriteWarningDismissed: false
  }),
  computed: {
    showFilesizeWarning() {
      return this.chooserState === 'selected' && this.filesizeWarning
    }
  },
  methods: {
    close() {
      this.cancel()
      this.$emit('close')
    },
    setState(val) {
      this.chooserState = val
    },
    updateProgress(val) {
      this.percentComplete = val
    },
    fileChanged(files) {
      this.file = files[0]
      this.chooserState = 'selected'
      if (this.file && this.file.size > 500000000) { // 500 MB
        this.filesizeWarning = true
      } else {
        this.filesizeWarning = false
      }

    },
    execute() {
      this.chooserState = 'inProgress'
      uploadFileForIngestion(this.file, this.dataset.id, this.$nioOpenApi).then(res => {
        this.chooserState = 'success'
        this.percentComplete = 100
      }, (err) => {
        this.chooserState = 'error'
      })
    },
    cancel() {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      this.reset()
      this.$emit('close')
    },
    reset() {
      this.chooserState = 'initial'
      this.percentComplete = 0
      this.file = null
    },
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.ingestion-upload-dialog
  padding: 3rem 4.875rem
  background-color: $c-white
  // border: 0.0625rem solid $c-primary-lighter
  border-radius: 0.75rem
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  .nio-icon-framer
    margin-bottom: 1rem
  h2
    margin-bottom: 1.5rem
  p    
    margin-bottom: 2rem
    text-align: center
    max-width: 31.25rem
  .warning-actions
    display: flex
    justify-content: center
    align-items: center
    & > * + * 
      margin-left: 1rem  
  .upload-actions
    position: absolute
    top: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
  .messages
    width: 100%
    margin-top: 1.5rem
</style>