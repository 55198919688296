<template lang="pug">
  .tree-menu(:class="{'display-only': displayOnly}")
    .field-container
      .indicator
        .csv-grip(v-if="fieldType === 'flat' && draggable")
          NioIcon(
            :size="14"
            name="utility-grip-vertical" 
            color="#415298" 
          )
      .field-text 
        .d-flex
          .nio-slat-image
            NioIcon(
              :name="dataTypeIconName({type: field.type})"
              :size="24"
              color="#AEB9E8"
            )
            
          .content
            h4.nio-h4.text-primary-light(v-if="field.name === 'nioArrayItemsField'") items
            h4.nio-h4.text-primary-darker(v-else) {{ field.name }}
            p.nio-p.text-primary-dark(v-if="field.description") {{ field.description }}
            p.nio-p.text-primary-light(v-else) Add a description
        NioTooltip.error-tooltip(
          v-if="fieldHasError"
          :icon-size="16"
          :open-delay="250"
          message="Each field of type Object must have a descendant of type String, Long, Double, Boolean or Timestamptz. You can fix this by adding a child field of one of these types."
          icon-name="utility-exclamation"
          icon-color="red"
          open-on-hover
        )
      .detail-set
        .item
          v-menu(
            :open-delay="250"
            :content-class="makeTooltipContentClass(field.type)"
            open-on-hover
            open-on-click
            right
          ) 
        .item.sellable-required.na(
          v-if="field.name === 'nioArrayItemsField'"
          :class="{'subdued': displayOnly}"
        )
          .nio-p.text-primary-dark N/A
          .nio-p.text-primary-dark N/A
        .item.sellable-required(
          v-else 
          :class="{'subdued': displayOnly}"
        )
          NioSwitch(
            v-model="sensitive"
            v-if="showSwitch('sensitive', field.name) && !field.path.includes('nioArrayItemsField')"
            @click.stop=""
            @update="updateSensitive($event)"
          ) 
          .nio-p-small.text-primary-dark(style="text-transform: none" v-if="showSwitch('sensitive', field.name) && !field.path.includes('nioArrayItemsField')") Sensitive 
          NioSwitch(
            v-model="required"
            v-if="showSwitch('required') && !field.path.includes('nioArrayItemsField')"
            @click.stop=""
            @update="updateRequired($event)"
          ) 
          .nio-p-small.text-primary-dark(style="text-transform: none" v-if="showSwitch('required') && !field.path.includes('nioArrayItemsField')") Required
          NioSwitch(
            v-model="sellable"
            v-if="showSwitch('required') && !field.path.includes('nioArrayItemsField')"
            @click.stop=""
            @update="updateSellable($event)"
          )
          .nio-p-small.text-primary-dark(style="text-transform: none" v-if="showSwitch('required') && !field.path.includes('nioArrayItemsField')") Sellable    
        //-   NioPill.subdued.negative(
        //-     v-if="field.name === 'nioArrayItemsField'"
        //-     list-item
        //-   ) N/A
        //-   NioPill(
        //-     v-else
        //-     list-item
        //-     prepend-icon
        //-     :iconName="isSellable(field) ? 'utility-check-circle' : 'utility-times-circle'"
        //-     :iconColor="isSellable(field) ? '#43B463' : '#AEB9E8'"
        //-     :class="field.is_sellable ? '' : 'negative'"
        //-   ) Sellable
        //- .item
        //-   NioPill.subdued.negative(
        //-     v-if="field.name === 'nioArrayItemsField'"
        //-     list-item
        //-   ) N/A
        //-   NioPill(
        //-     v-else
        //-     list-item
        //-     prepend-icon
        //-     :iconName="isRequired(field) ? 'utility-check-circle' : 'utility-times-circle'"
        //-     :iconColor="isRequired(field) ? '#43B463' : '#AEB9E8'"
        //-     :class="!field.is_optional ? '' : 'negative'"
        //-   ) Required
      NioOverflowMenu(v-if="!displayOnly")
        template(v-slot:items)  
          v-list
            v-list-item(
              v-if="(field.type === 'object') && fieldType === 'json'" 
              @click="emitNew"
            ) Add Child
            v-list-item(@click="emitEdit") Edit
            v-list-item(
              v-if="field.name !== 'nioArrayItemsField'" 
              @click="confirmDeleteDialog = true"
            ) Delete
    .subtree(v-for="(nest, idx) in field.properties")
      TreeMenu(
        :field="nest"
        :id="idx"
        :field-type="fieldType"
        :draggable="draggable"
        :display-only="displayOnly"
        :required-fields="field.required"
        :unsellable-fields="field.unsellable"
        :sensitive-fields="field.sensitive"
        @showEditDialog="showEditDialog"
        @toggleChanged="toggleChanged($event, nest)"
        :isEdit="true"
        :isNested="true"
      )
    .subtree(v-for="(nest, idx) in field.items")
      TreeMenu(
        :field="nest" 
        :id="idx" 
        :field-type="fieldType" 
        :draggable="draggable" 
        :display-only="displayOnly"
        @showEditDialog="showEditDialog"
        @toggleChanged="toggleChanged($event, nest)"
        :isEdit="true"
        :isNested="true"
      )
    NioDialog(
      v-model="confirmDeleteDialog" 
    )
      ConfirmDeleteDatasetDialog(
        :field-type="field.type"
        @cancel="confirmDeleteDialog = false"
        @confirm="confirmDelete"
      )      
</template>

<script>
import { NioPill, NioIcon, NioIconFramer } from '@narrative.io/tackle-box'
import { mapGetters, mapActions } from 'vuex'
import { capitalize } from '@/modules/helpers'
import ConfirmDeleteDatasetDialog from './ConfirmDeleteDatasetDialog'
import { getDataTypeIconName, getReadableType } from "@narrative.io/tackle-box/src/modules/app/schema/attributeModule"

export default {
  name: 'TreeMenu',
  components: { NioPill, NioIcon, NioIconFramer, ConfirmDeleteDatasetDialog },
  props: {
    field: Object,
    id: Number,
    draggable: Boolean,
    nestedPath: String,
    fieldType: String,
    displayOnly: { type: Boolean, required: false, default: false },
    requiredFields: { type: Array, required: false },
    unsellableFields: { type: Array, required: false },
    sensitiveFields: { type: Array, required: false },
    isEdit: {type: Boolean, required: false, default: false},
    isNested: {type: Boolean, required: false, default: false}
  },
  data: () => ({
    sellable: false,
    sensitive: false,
    required: false,
    confirmDeleteDialog: false
  }),
  computed: {
    
    ...mapGetters(['getFieldsValidation']),
    icon() {
      switch(this.field.type) {
        case 'string':
          return 'utility-font'
        case 'boolean':
          return 'utility-switch'
        case 'timestamptz':
          return 'utility-clock'
        case 'double':
        case 'long':
          return 'utility-hashtag'
        case 'object':
          return 'utility-chevron-right'
        case 'array':
          return 'utility-numbered-list'
        default:
          return 'utility-font'
      }
    },
    fieldHasError() {
      const validationErrors = this.getFieldsValidation
      let hasError = false
      validationErrors.errors.map(error => {
        if (!hasError && error.fields) {
          error.fields.forEach(errorField => {
            if (errorField === this.field.path) {
              hasError = true
            }
          })
        }
      })
      return hasError
    }
  },
  watch: {
    field: {
      deep: true,
      handler() {
        this.required = this.computeRequired()
        this.sellable = this.computeSellable()
        this.sensitive = this.computeSensitive()
      }
    }
  },
  mounted() {
    this.required = this.computeRequired()
    this.sellable = this.computeSellable()
    this.sensitive = this.computeSensitive()
  },
  methods: {
    showSwitch(field, name) {
      if(!this.isEdit) return true
      return field === 'sensitive' &&  !this.sensitiveFields?.includes(name)
    },
    makeTooltipContentClass(attributeType) {
      return `nio-field-type-tooltip ${attributeType}`
    },
    getPropertyType(property) {
      return getReadableType(property)
    },
    dataTypeIconName(property) {
      return getDataTypeIconName(property)
    },
    ...mapActions(['setFieldToEdit', 'getFieldByPath', 'deleteFieldByPath']),
    translateBoolean(val) {
      return val ? 'Yes' : 'No'
    },
    emitEdit() {
      this.$emit('showEditDialog')
      this.setFieldToEdit(this.field.path)
    },
    emitNew() {
      this.$emit('showEditDialog')
      this.setFieldToEdit(this.field.path + '/nioNewField')
    },
    emitDelete() {
      this.deleteFieldByPath(this.field.path)
    },
    capitalize(s) {
      return capitalize(s)
    },
    computeRequired() {
      if (this.displayOnly) {
        if (!this.requiredFields) {
          return false
        }
        const arrPath = this.field.path.split('/')
        arrPath.shift()
        return this.requiredFields.includes(arrPath[arrPath.length - 1])
      } else {
        return !this.field.is_optional
      }
    },
    computeSellable() {
      if (this.displayOnly) {
        if (!this.unsellableFields) {
          return true
        }
        const arrPath = this.field.path.split('/')
        arrPath.shift()
        return !this.unsellableFields.includes(arrPath[arrPath.length - 1])
      } else {
        return this.field.is_sellable
      }
    },
    computeSensitive() {
      if (this.displayOnly) {
        if (!this.sensitiveFields) {
          return false
        }
        const arrPath = this.field.path.split('/')
        arrPath.shift()
        return this.sensitiveFields.includes(arrPath[arrPath.length - 1])
      } else {
        return this.field.is_sensitive
      }
    },
    updateSellable(val, nest) {
      this.$emit('toggleChanged', {type: 'sellable', value: this.sellable, path: this.field.path, field: this.field})
    },
    updateRequired(val) {
      this.$emit('toggleChanged', {type: 'required', value: this.required, path: this.field.path, field: this.field})
    },
    updateSensitive(val) {
      this.$emit('toggleChanged', {type: 'sensitive', value: this.sensitive, path: this.field.path, field: this.field})
    },
    toggleChanged(val) {
      this.$emit('toggleChanged', val)
    },
    showEditDialog() {
      this.$emit('showEditDialog')
    },
    confirmDelete() {
      this.confirmDeleteDialog = false
      this.emitDelete()
    }
  }
}
</script>

<style lang="sass">
@import "@narrative.io/tackle-box/src/styles/mixins/_tooltip-content"
.nio-field-type-tooltip
  +nio-tooltip-content
  max-width: 8.75rem
  .content
    margin: 0 auto
    max-width: 30rem
    overflow: hidden
    white-space: pre-line
    .nio-h4
      margin-bottom: 0.25rem
</style>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"
.tree-menu
  background-color: white
  .nio-icon-framer
    background-color: white
    &:hover
      cursor: initial
  &.display-only
    background-color: $c-canvas
    .nio-icon-framer
      background-color: $c-canvas
  .subtree
    margin-left: 2rem
  
  .nio-slat-image 
    margin-right: 1rem  
    width: 3rem
    height: 3rem 
    justify-content: center
    align-items: center
    display: flex
    background-color: $c-canvas
    border: 0.0625rem solid $c-primary-lighter
    border-radius: 0.25rem       

  .field-container
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 0.0625rem solid $c-primary-lighter
    padding: 0 1rem
    &.has-error
      border-left: 0.1875rem solid $c-error
    .csv-grip
      padding: 1rem
      &:hover
        cursor: grab
    .field-text
      flex: auto
      padding: 1rem 0
      display: flex
      align-items: center
      .content
        display: block
        h4 
          overflow-wrap: anywhere
          padding-right: 0.625rem
        p
          margin: 0
          padding: 0
          padding-right: 0.625rem
          overflow-wrap: anywhere
      .nio-tooltip
        margin-right: 1rem
        transform: translateY(0.0625rem)
        & ::v-deep *:hover
          cursor: initial !important
          opacity: 1 !important
    .detail-set
      display: flex
      .item
        margin-right: 0rem
        display: flex
        align-items: center
        .nio-switch
          ::v-deep .v-input__slot
            margin-bottom: 0rem
          ::v-deep .v-messages
            display: none
          ::v-deep .v-input__control
            margin-bottom: 0.25rem
        .nio-p-small
          margin-top: 0.0625rem
        .nio-pill
          width: 7.625rem
        .negative
          background-color: $c-canvas
        &.subdued
          opacity: 0.65
        .nio-pill
          &.append-icon, &.prepend-icon
            .append, .prepend
              &:hover
                cursor: default
                ::v-deep svg
                  opacity: 1
        &.sellable-required
          display: flex
          justify-content: flex-end
          align-items: center
          width: unset
          height: 4.75rem
          width: 21.5625rem
          margin-right: 1rem
          &.na 
            justify-content: flex-start
            padding-left: 1.6875rem
            & > * + *
              margin-left: 6rem
          .nio-switch
            margin-bottom: 0rem
            margin-top: 0rem
            width: 3.125rem
            margin-left: 0.5rem
            .v-input__slot
              margin-bottom: 0rem
            .v-messages
              display: none
          & > .nio-switch + .nio-switch
            margin-left: 0rem
          .nio-p-small
            margin-left: 0.25rem
  .data
    display: flex
    justify-content: space-between
    margin-top: 1rem
    .h
      text-transform: uppercase
      font-weight: 600
    .t
      padding-top: 1rem
</style>