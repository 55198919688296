import { render, staticRenderFns } from "./IngestionUploadDialog.vue?vue&type=template&id=21bb1475&scoped=true&lang=pug&"
import script from "./IngestionUploadDialog.vue?vue&type=script&lang=js&"
export * from "./IngestionUploadDialog.vue?vue&type=script&lang=js&"
import style0 from "./IngestionUploadDialog.vue?vue&type=style&index=0&id=21bb1475&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21bb1475",
  null
  
)

export default component.exports