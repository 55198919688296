import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

let requestUploadUrl = async (path, openApi) => {
  const resp = await openApi.post(`/uploads/${path}`)
  if (resp.status === 200) {
    return Promise.resolve(resp)
  } else {
    return Promise.reject('There was an error requesting the upload path.')
  }
}

let uploadFile = async (file, path) => {
  const resp = await axios.put(path, file)
  if (resp.status === 200) {
    return Promise.resolve(resp)
  } else {
    return Promise.reject('There was an error uploading the file')
  }
}

let ingestFileToDataset = async (datasetId, path, openApi) => {
  let body = {
    source_file: path
  }
  const resp = await openApi.post(`/datasets/${datasetId}/upload`, JSON.stringify(body))
  if (resp.status === 200) {
    return Promise.resolve(resp)
  } else {
    return Promise.reject('There was an error ingesting the file')
  }
}

let uploadFileForIngestion = async (file, datasetId, openApi) => {
  const path = file.name
  let uploadFileResp
  let uploadResp
  try {
    uploadResp = await requestUploadUrl(path, openApi)
  } catch (error) {
    return Promise.reject(error)
  }
  try {
    uploadFileResp = await uploadFile(file, uploadResp.data.url)
  } catch (error) {
    return Promise.reject(error)
  }
  try {
    let resp = await ingestFileToDataset(datasetId, uploadResp.data.path, openApi)
    return Promise.resolve(resp)
  } catch (error) {
    return Promise.reject(error)
  }
}

export {
  uploadFileForIngestion
}